<template>
  <div class="app-mall-list container">
    <div class="content padding-20-0" v-loading="loading">
      <div class="h wrap" v-if="data && data.length">
        <group-item v-for="g in data" :key="g.id" :group="g" size="200px" />
      </div>
      <div class="fc-g ta-c" v-else>目前还没有组合销售哦</div>
    </div>
  </div>
</template>

<script>
import GroupItem from "../../assembly/group-item";
import initData from "@/mixins/initData";

export default {
  components: { GroupItem },
  mixins: [initData],
  props: {
    supplier: String | Number
  },
  watch: {
    supplier: "init"
  },
  methods: {
    beforeInit() {
      this.url = "api/promotion/groupSale";
      this.params.sellerId = this.supplier;
      return true;
    }
  },
  mounted() {
    this.init();
  }
};
</script>